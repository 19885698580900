export default [
    {
        id: 'auxiliaries',
        title: ' | ',
        route: null,
        permission: 'Dashboard',
    },
    //GEI
    {
        header: 'Auxiliares',
        permission: 'list_gei_providers',
        children: [
            {
                title: 'Proveedores',
                route: 'list_auxiliar_providers_gei',
                permission: 'list_gei_providers',
            },
            {
                title: 'Gestor Responsables',
                route: 'list_auxiliar2_responsibles',
                permission: 'list_gei_providers',
            },
            {
                title: 'Gestor de Proyectos',
                route: 'list_auxiliar2_proyects',
                permission: 'list_gei_providers',
            },
            {
                title: 'Ver Tipos de Clientes',
                route: 'list_auxiliar_clientTypes_gei',
                permission: 'list_gei_providers',
            },
            {
                title: 'Ver Tipos de Instalaciones',
                route: 'list_auxiliar_installationTypes_gei',
                permission: 'list_gei_providers',
            },
            {
                title: 'Ver Productos',
                route: 'list_auxiliar_products_gei',
                permission: 'list_gei_providers',
            },
            {
                title: 'Ver Tipos de Documentos',
                route: 'list_auxiliar_documentTypes_gei',
                permission: 'list_gei_providers',
            },
            {
                title: 'Ver Inspecciones Realizadas',
                route: 'list_auxiliar_inspectionMadeBy_gei',
                permission: 'list_gei_providers',
            },
            {
                title: 'Ver Nivel 2',
                route: 'list_auxiliar_level2_gei',
                permission: 'list_gei_providers',
            },
        ]
    },

    //VHE
    {
        header: 'Auxiliares',
        permission: 'see_auxiliaries_vhe',
        children: [
            {
                title: 'Proveedores',
                route: 'list_auxiliar_providers_vhe',
                permission: 'see_auxiliaries_vhe',
            },
            {
                title: 'Ver Tipos de Clientes',
                route: 'list_auxiliar_clientTypes_vhe',
                permission: 'see_auxiliaries_vhe',
            },
            {
                title: 'Ver Tipos de Instalaciones',
                route: 'list_auxiliar_installationTypes_vhe',
                permission: 'see_auxiliaries_vhe',
            },
            {
                title: 'Ver Productos',
                route: 'list_auxiliar_products_vhe',
                permission: 'see_auxiliaries_vhe',
            },
            {
                title: 'Ver Tipos de Documentos',
                route: 'list_auxiliar_documentTypes_vhe',
                permission: 'see_auxiliaries_vhe',
            },
            {
                title: 'Ver Inspecciones Realizadas',
                route: 'list_auxiliar_inspectionMadeBy_vhe',
                permission: 'see_auxiliaries_vhe',
            },
            {
                title: 'Ver Nivel 1',
                route: 'list_auxiliar_level1_vhe',
                permission: 'see_auxiliaries_vhe',
            },
            {
                title: 'Ver Nivel 2',
                route: 'list_auxiliar_level2_vhe',
                permission: 'see_auxiliaries_vhe',
            },
            {
                title: 'Ver Nivel 3',
                route: 'list_auxiliar_level3_vhe',
                permission: 'see_auxiliaries_vhe',
            },
            {
                title: 'Ver Nivel 4',
                route: 'list_auxiliar_level4_vhe',
                permission: 'list_vhe_providers',
            },
        ]
    },
]

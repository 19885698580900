<template>
  <div class="navbar-header d-xl-block d-none">
    <ul class="nav navbar-nav">
      <li class="nav-item">
        <b-link
          class="navbar-brand"
          to="/"
        >
          <img
            :src="imgLogo"
            alt=""
            height="50"
          >
        </b-link>

      </li>

    </ul>

  </div>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
  computed: {
    imgLogo() {
      // eslint-disable-next-line global-require
      return require('@/assets/images/logo/logo.png')
    },
      platform(){
         return authService.activePlatform()
      }
  },
}
</script>

<style>

</style>

export default [
    {
        id: 'reports',
        title: '|',
        route: null,
        permission: 'Dashboard',
    },
    {
        header: 'Informes',
        permission: 'Dashboard',
        children: [
            {
                title: 'Informe de Requisitos Legales',
                route: 'dashboard_metrics_gei',
                permission: 'list_dashboard_metrics',
            },
            {
                title: 'Listado Instalaciones GEI',
                route: 'dashboard_metrics_installations_list',
                permission: 'list_dashboard_metrics',
            },
            {
                title: 'Listado Instalaciones hijas GEI',
                route: 'dashboard_metrics_children_installations_list',
                permission: 'list_dashboard_metrics',
            },
            {
                title: 'Informe de Requisitos Legales VHE',
                route: 'dashboard_metrics_vhe',
                permission: 'list_dashboard_metrics_vhe',
            },
            {
                title: 'Listado Instalaciones VHE',
                route: 'dashboard_metrics_installations_list_vhe',
                permission: 'list_dashboard_metrics_vhe',
            },
            {
                title: 'Listado Instalaciones hijas VHE',
                route: 'dashboard_metrics_children_installations_list_vhe',
                permission: 'list_dashboard_metrics_vhe',
            },
            {
                title: 'Listado Planificación Prevista VHE',
                route: 'dashboard_metrics_planned_planning_vhe',
                permission: 'list_dashboard_metrics_vhe',
            },
            {
                title: 'Listado Mantenimientos VHE',
                route: 'dashboard_metrics_maintenance_vhe',
                permission: 'list_dashboard_metrics_vhe',
            },
        ]
    },
]

<template>
    <div class="navbar-container d-flex content align-items-center">

        <!-- Nav Menu Toggler -->
        <ul class="nav navbar-nav d-xl-none">
            <li class="nav-item">
                <b-link
                    class="nav-link"
                    @click="toggleVerticalMenuActive"
                >
                    <feather-icon
                        icon="MenuIcon"
                        size="21"
                    />
                </b-link>

            </li>

        </ul>

        <!-- Left Col -->
        <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex ml-5">
            <b-badge
                pill
                variant="light-primary"
                class="ml-3 mt-0 font-small-4"
            >
                {{ platform }}
            </b-badge>
        </div>


        <!-- Right Col -->
        <b-navbar-nav class="nav align-items-center ml-auto">
            <notification-dropdown/>
            <user-dropdown/>
        </b-navbar-nav>
    </div>
</template>

<script>
import {
    BLink, BNavbarNav, BBadge
} from 'bootstrap-vue'
// import Bookmarks from './components/Bookmarks.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'
import authService from "@/services/auth/authService"

export default {
    components: {
        BLink,
        BBadge,

        // Navbar Components
        BNavbarNav,
        // Bookmarks,
        NotificationDropdown,
        UserDropdown,
    },
    props: {
        toggleVerticalMenuActive: {
            type: Function,
            default: () => {
            },
        },
    },
    computed: {
        platform() {
            return authService.activePlatform()
        }
    },

}
</script>
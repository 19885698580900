<template>
	<ul
		id="main-menu-navigation"
    class="navbar-nav mr-auto d-flex flex-wrap"
	>
		<component
			:is="resolveNavComponent(item)"
			v-for="item in items"
			v-if="can(item.permission)"
			:key="item.header || item.title"
			:item="item"
		/>
	</ul>
</template>

<script>
import HorizontalNavMenuHeaderLink from '../horizontal-nav-menu-header-link/HorizontalNavMenuHeaderLink.vue'
import HorizontalNavMenuHeaderGroup from '../horizontal-nav-menu-header-group/HorizontalNavMenuHeaderGroup.vue'

export default {
	components: {
		HorizontalNavMenuHeaderLink,
		HorizontalNavMenuHeaderGroup,
	},
	props: {
		items: {
			type: Array,
			required: true,
		},
	},
	methods: {
		can(permission) {
			return this.$store.getters.can(permission)
		},
	},
	setup() {
		const resolveNavComponent = item => {
			if (item.children) return 'horizontal-nav-menu-header-group'
			return 'horizontal-nav-menu-header-link'
		}

		return {
			resolveNavComponent,
		}
	},
}
</script>

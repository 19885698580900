<template>
  <layout-horizontal>

    <router-view :key="$route.path"/>

  </layout-horizontal>

</template>

<script>
import LayoutHorizontal from '@core/layouts/layout-horizontal/LayoutHorizontal.vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    LayoutHorizontal,
  },
  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer,
    }
  },
}
</script>

export default [
    {
        id: 'auxiliaries2',
        title: '  |  ',
        route: null,
        permission: 'see_auxiliaries2_vhe',
    },

    //VHE
    {
        header: 'Auxiliares 2',
        permission: 'see_auxiliaries2_vhe',
        children: [
            {
                title: 'Gestor Responsable',
                route: 'auxiliar2_list_responsible_manager_vhe',
                permission: 'see_auxiliaries2_vhe',
            },
            {
                title: 'Gestor de Proyectos',
                route: 'auxiliar2_list_project_manager_vhe',
                permission: 'see_auxiliaries2_vhe',
            },
            {
                title: 'Incidencias Mantenimiento Correctivo',
                route: 'auxiliar2_list_corrective_maintenance_incident_vhe',
                permission: 'see_auxiliaries2_vhe',
            },
        ]
    },
]

export default [
    {
        id: 'installations',
        title: '| ',
        route: null,
        permission: 'Dashboard',
    },
    {
        header: 'Instalaciones',
        permission: 'Dashboard',
        children: [
            //GEI
            {
                title: 'Añadir nueva instalación GEI',
                route: 'create_master_installation',
                permission: 'create_gei_master',
            },
            {
                title: 'Añadir nueva instalación hija GEI',
                route: 'create_children_installation',
                permission: 'create_gei_children',
            },
            {
                title: 'Ver todas instalaciones GEI',
                route: 'list_master_installations',
                permission: 'list_gei_master',
            },
            {
                title: 'Ver todas instalaciones hijas GEI',
                route: 'list_children_installations',
                permission: 'list_gei_children',
            },

            //VHE
            {
                title: 'Añadir nueva instalación VHE',
                route: 'create_master_installation_vhe',
                permission: 'create_vhe_master',
            },
            {
                title: 'Añadir nueva instalación hija VHE',
                route: 'create_children_installation_vhe',
                permission: 'create_vhe_children',
            },
            {
                title: 'Ver todas instalaciones VHE',
                route: 'list_master_installations_vhe',
                permission: 'list_vhe_master',
            },
            {
                title: 'Ver todas instalaciones hijas VHE',
                route: 'list_children_installations_vhe',
                permission: 'list_vhe_children',
            },
        ]
    },
    {
        header: 'Instalaciones',
        permission: 'Dashboard VHE',
        children: [
            {
                title: 'Añadir nueva instalación VHE',
                route: 'create_master_installation_vhe',
                permission: 'create_vhe_master',
            },
            {
                title: 'Añadir nueva instalación hija VHE',
                route: 'create_children_installation_vhe',
                permission: 'create_vhe_children',
            },
            {
                title: 'Ver todas instalaciones VHE',
                route: 'list_master_installations_vhe',
                permission: 'list_vhe_master',
            },
            {
                title: 'Ver todas instalaciones hijas VHE',
                route: 'list_children_installations_vhe',
                permission: 'list_vhe_children',
            },
        ]
    },
]

<template>
	<div class="navbar-container main-menu-content">
        <div class="navbar" style="padding: 0">
            <horizontal-nav-menu-items :items="navMenuItems"/>

            <horizontal-nav-menu-left-items :items="navMenuItemsLeft"/>
        </div>
    </div>
</template>

<script>
import navMenuItems from '@/views/menu'
import navMenuItemsLeft from '@/views/menu/menu-left'

import HorizontalNavMenuItems from './components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue'
import HorizontalNavMenuLeftItems from './components/horizontal-nav-menu-items/HorizontalNavMenuLeftItems.vue'

export default {
	components: {
		HorizontalNavMenuItems,
        HorizontalNavMenuLeftItems,
	},
	setup() {
		return {
			navMenuItems,
            navMenuItemsLeft,
		}
	},
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>

import dashboard from './dashboard'
import auxiliars from './auxiliars'
import secondAuxiliars from './second_auxiliars'

import installations from './installations'

import reports from './reports'

// Array of sections
export default [
    ...dashboard,
    ...auxiliars,
    ...secondAuxiliars,
    /*...auxiliars, ...secondAuxiliars, ...masters,*/
    ...installations,
    ...reports
]

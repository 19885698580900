<template>
    <b-nav-item-dropdown
        class="dropdown-notification mr-25"
        menu-class="dropdown-menu-media"
        right
    >
        <template #button-content>
            <feather-icon
                :badge="notifications.length"
                badge-classes="bg-danger"
                class="text-body"
                icon="BellIcon"
                size="21"
            />

        </template>
        <!-- Header -->
        <li class="dropdown-menu-header">
            <div class="dropdown-header d-flex">
                <h4 class="notification-title mb-0 mr-auto">
                    Notificacions
                </h4>
                <b-badge
                    pill
                    variant="light-primary"
                >
                    {{ notifications.length }} Noves
                </b-badge>
            </div>
        </li>

        <!-- Notifications -->
        <div
            class="scrollable-container media-list scroll-area"
        >
            <!-- Account Notification -->
            <b-link
                v-for="notification in notifications"
                :key="notification.id"
                :href="notification.url"
            >
                <b-media>
                    <template #aside>
                        <b-avatar
                            size="32"
                            :variant="'danger'"
                        />
                    </template>
                    <p class="media-heading">
                        <b-row class="justify-content-end float-right border-1" @click="markAsRead(notification.id)">
                            <b-badge pill variant="danger">
                                X
                            </b-badge>
                        </b-row>
                        <span class="font-weight-bolder">
                          {{ notification.text }}
                        </span>
                    </p>
                </b-media>
            </b-link>
        </div>

        <!-- Cart Footer -->
        <li class="dropdown-menu-footer">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                block
                @click="markAllAsRead()"
            >
                Marcar notificacions com a llegides
            </b-button>
        </li>
    </b-nav-item-dropdown>
</template>

<script>
import {BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {mapState} from "vuex"

export default {
    name: 'NotificationDropdown',
    components: {
        BNavItemDropdown,
        BBadge,
        BMedia,
        BLink,
        BAvatar,
        BButton,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            userData: this.$store.state.AppActiveUser,

            perfectScrollbarSettings: {
                maxScrollbarLength: 60,
                wheelPropagation: false,
            }
        }
    },
    computed:{
        ...mapState('user', ['notifications'])
    },
    methods: {
        markAllAsRead() {
            this.$store.dispatch('user/markNotificationsAsRead', this.userData.id)
                .then(response => {
                    this.getNotifications()
                })
                .catch(error => {
                    console.log(error)
                })
        },
        markAsRead(id) {
            this.$store.dispatch('user/markNotificationAsRead', id)
                .then(response => {
                    this.getNotifications()
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }
}
</script>

<style>
.media-list{
    overflow: hidden;
    overflow-y: scroll;
}
</style>
